module.exports = function currencize(price) {
  let asString = price.toFixed(2).toString();
  // console.log(asString);
  let stack = [];
  for(let i=asString.length-1; i>=0; i-- ) {
    const iter = asString.length - i;
    if((iter - 1)  % 3 === 0 && iter >= 6) {
      stack.push(',');
    }
    stack.push(asString[i]);
  }
  stack.push('$');
  let returnString = "";
  for(let i=stack.length-1; i>=0; i--) {
    returnString += stack[i];
  }
  return returnString;
}