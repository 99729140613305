import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Image from '../components/image';
import MainImage from '../components/mainImage';
import FCSizeShart from '../components/frenchcreek-size-chart';
import productStyles from './product.module.scss';
import Title from '../components/title';
import currensize from '../../util/currencize';
import slugify from '../../util/slugify';

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        cartUrl
      }
    }
    markdownRemark(fields: {slug: {eq: $slug } }) {
      frontmatter {
        title
        date
        baseCode
        category
        basePrice
        sizeChart
        features
        images {
          src
          alt
        }
        variations {
          name
          modType
          options {
            name
            price
            code
          }
        }
      }
      html
    }
  }
`

const Product = (props) => {
  const md = props.data.markdownRemark;
  const vars = md.frontmatter.variations;
  const basePrice = md.frontmatter.basePrice;
  const baseCode = md.frontmatter.baseCode;
  const [currentPrice, setCurrentPrice] = useState(basePrice);
  const [currentOptions, setCurrentOptions] = useState(() => {
    if (vars) {
      return vars.map(() => (0))
    } else {
      return null;
    }
  });

  const [currentCode, setCurrentCode] = useState(baseCode);



  function handleSelect(i, j) {
    let newOptions = currentOptions;
    newOptions[i] = parseInt(j);
    let newPrice = basePrice;
    let newCode = baseCode;
    if (vars) {
      vars.forEach((vari, i) => {
        if (vari.modType === "add")
          newPrice += vari.options[currentOptions[i]].price;
        if (vari.options[currentOptions[i]].code)
          newCode += vari.options[currentOptions[i]].code;
      });
    }
    setCurrentPrice(newPrice);
    setCurrentOptions(newOptions);
    setCurrentCode(newCode)

  }


  useEffect(() => {
    let newCode = baseCode;
    if (vars) {
      vars.forEach((vari, i) => {
        if (vari.options[currentOptions[i]].code)
          newCode += vari.options[currentOptions[i]].code;
      });
    }
    setCurrentCode(newCode);
    return undefined;
  }, [currentOptions, vars, baseCode]);

  function assembleOptionString(option, variation, product) {
    let valueString = option.name;
    if (variation.modType !== 'none') {
      if (variation.modType) {
        if (variation.modType === 'add' && option.price) {
          valueString += ` (+$${option.price.toFixed(2)})`
        } else if (variation.modType === 'set') {
          valueString += ` - $${option.price.toFixed(2)}`
        }
      }
    }
    return valueString;
  }

  function assembleForm() {
    return (
      <div>
        <form action={props.data.site.siteMetadata.cartUrl}
          method="post"
          acceptCharset="utf-8"
          className={productStyles.purchaseForm}
        >
          <input type="hidden" name="code" value={currentCode} />
          <input type="hidden" name="price" value={currentPrice} />
          <input type="hidden" name="name" value={md.frontmatter.title} />
        
          <div className="field">
            <div className="field-label">
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                <h2 className={productStyles.productPrice}>{currensize(currentPrice)}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="field-label">
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className={`button is-success ${productStyles.buyButton}`}>
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) 
  }
      
      
  return (
    <Layout title={`Order ${md.frontmatter.title} online at USSafetyEquipment.com`} metaDesc={'Order online quickly, easily, and securely.'}>
      <Title breadcrumb={{ slug: slugify(md.frontmatter.category), name: md.frontmatter.category }}>{md.frontmatter.title.toUpperCase()}</Title>
      <div className={productStyles.productContainer}>
        <div className="columns is-desktop">
          <div className="column is-1">
          </div>
          <div className="column is-10">
            <div className="columns">
              <div className="column is-half">
                {md.frontmatter.images &&
                  <div>
                    <div >
                      <div>
                        <MainImage imgSrc={md.frontmatter.images[0].src} imgAlt={md.frontmatter.images[0].alt} />
                      </div>
                    </div>
                    <div className={`thumbnail-row columns is-mobile ${productStyles.thumbnailRow} is-centered`}>
                      {md.frontmatter.images.map((image, i) => {
                        if (i > 0) {
                          return (
                            <div key={`image-${i}`} className="column">
                              <Image imgSrc={image.src} imgAlt={image.alt} />
                            </div>
                          );
                        } 
                      })}
                    </div>
                  </div>
                }
              </div>
              <div className="column">
                {md.frontmatter.features &&
                  <div>
                    <h3>Features</h3>
                    <ul>
                      {md.frontmatter.features.map((detail, i) => (
                        <li key={`detail-${i}`}>{detail}</li>
                      ))}

                    </ul>
                  </div>
                }
                {/* <h1>{md.frontmatter.title}</h1> */}



                {vars &&
                  <div>
                    {vars.map((vari, i) => (
                      <div key={`field-${i}`} className="field">
                        <div className="">
                          <label className="label">
                            {vari.name}
                          </label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <div className="select is-fullwidth">
                                <select name={vari.name} onChange={(e) => handleSelect(i, e.target.value)}>
                                  {vari.options.map((opt, j) => (
                                    // <option key={`option-${i}-${j}`} value={assembleOptionValue(opt, vari)}>
                                    <option key={`option-${i}-${j}`} value={j}>
                                      {assembleOptionString(opt, vari, md.frontmatter)}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))}

                  </div>
                }
                {assembleForm()}

                {md.frontmatter.sizeChart &&
                  <div className={productStyles.sizeChart}>
                    <h3>Size Chart</h3>
                    <FCSizeShart />
                  </div>
                }
              </div>
            </div>

          </div>
          <div className="column is-1"></div>
        </div>

        <div className={`columns ${productStyles.descriptionContainer}`}>
          <div className="column is-1">
          </div>
          <div className="column is-10">
            <div dangerouslySetInnerHTML={{ __html: md.html }}>
            </div>
          </div>
          <div className="column is-1"></div>
        </div>



      </div>
    </Layout >
  )
}
  
export default Product;
  
        /*
        
  <div className="table-wrapper">
            {vars &&
              <table className="table">
                <thead>
                  <tr>
                    {vars.map((vari, i) => (
                      <td key={`header-${i}`}>{vari.name}</td>
                    ))}
                  </tr>
                </thead>
                {assembleRows()}

              </table>
            }

          </div>

          function assembleRows() {

            let n = vars.length;
          let indexArray = [];
  vars.forEach((vari, i) => {
            indexArray.push(0);
          });
          let outputArray = [];
  while (1) {
            outputArray.push(indexArray.map((j, i) => {
              return j;
            }));


          let next = n - 1;
      
          while (next >= 0 &&
      (indexArray[next] + 1 >= vars[next].options.length)) {
            next--;
          }
      
    if (next < 0) {
      break;
        }
    
        indexArray[next]++;
    for (var i = next + 1; i < n; i++)
            indexArray[i] = 0;
        }
        console.log(outputArray);
        return (
    <tbody>
            {outputArray.map(out => {
              return (
                <tr>
                  {out.map((index, j) => {
                    console.log(index, vars[j].options[index])
                    return (<td key={`cell-${j}-${index}`}>{vars[j].options[index].name}</td>)
                  })}
                </tr>
              )
            })}
          </tbody>
          )
        }
*/