import React from 'react';
// import Modal from './modal';
import mainImageStyles from './mainImage.module.scss';

export default class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false};
  }

  render() {
    return (
      <div className="image-wrapper">

        <img className={`thumb ${mainImageStyles.image}`}
          style={{display: 'block', margin: '0 auto'}}
          src={`/images/${this.props.imgSrc}`}
          alt={this.props.imageAlt || ''}
          onClick={() => this.setState({modalOpen: true})}/>

        <div className={`modal ${this.state.modalOpen ? 'is-active' : ''}`}>
          <div className="modal-background" onClick={() => this.setState({modalOpen: false})}></div>
            <div className="modal-content">
              <p className="image">
                <img style={{maxHeight: '100%'}} src={`/images/${this.props.imgSrc}`} alt={this.props.imageAlt || ''} />
              </p>
            </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({modalOpen: false})}></button>
        </div>

      </div>
    )
  }
}