import React from 'react';
import { Link } from 'gatsby';

import titleStyles from './title.module.scss'

const Layout = (props) => {
  return (
    <div>
      <div className={titleStyles.breadcrumbWrap}>
        {props.breadcrumb &&
          <Link to={props.breadcrumb.slug}>
            


            <h2 className={titleStyles.breadcrumb}>Back to {props.breadcrumb.name}</h2>
          </Link>

        }
      </div>
      <div className={titleStyles.titleWrap}>

        <h2 className={titleStyles.title}>{props.children}</h2>

      </div>
    </div>

  )
}

export default Layout;