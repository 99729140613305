import React from 'react';

const SizeChart = () => {
  return (
    <table className="size-chart table">
      <thead>
        <tr>
        <th>Size</th>
          <th>XS</th>
          <th>S</th>
          <th>M</th>
          <th>L</th>
          <th>XL</th>
          <th>XXL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Waist</th>
          <td>28-36</td>
          <td>32-40</td>
          <td>36-44</td>
          <td>40-48</td>
          <td>44-52</td>
          <td>48-56</td>
        </tr>
      </tbody>
    
    </table>
    
  )
}

export default SizeChart