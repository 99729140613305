import React from 'react';
// import Modal from './modal';
import imageStyles from './image.module.scss';

export default class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalOpen: false};
  }

  render() {
    return (
      <div className={imageStyles.wrapper}>

        <img className={imageStyles.thumb} src={`/images/${this.props.imgSrc}`} alt={this.props.imageAlt || ''} onClick={() => this.setState({modalOpen: true})}/>

        <div className={`modal ${this.state.modalOpen ? 'is-active' : ''}`}>
          <div className="modal-background" onClick={() => this.setState({modalOpen: false})}></div>
            <div className="modal-content">
              <p className="image">
                <img style={{maxHeight: '100%'}} src={`/images/${this.props.imgSrc}`} alt={this.props.imageAlt || ''} />
              </p>
            </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({modalOpen: false})}></button>
        </div>

      </div>
    )
  }
}